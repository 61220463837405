/* =====================================================================================================================
 * zgPost
 *
 *
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ================================================================================================================== */

(function ( $, _ ) { /* global _, DEBUG, JS_TRANSLATIONS */
	'use strict';


	// Establish the root object ('window' in the browser)
	var root = this;


	/**
	 *
	 * @param {string} type
	 * @param {string} action
	 * @param {Object} data
	 * @param {Object} msg
	 * @param {Object} callback
	 * @private
	 */
	function __request ( type, action, data, msg, callback, dataType ) {

		if ( action ) {

			$.ajax( {
				type:     type || 'POST',
				url:      window.makeUrl( { module: 'eshop', manager: 'eshop', action: action } ),
				data:     data,
				dataType: dataType || 'json',

				success: function ( response ) {
					// Some of our request have a response.status others don't ( consistency is for noobs )  (T-T)
					if ( !response.status || response.status === 'success' ) {

						__message( action, 'notification', ( msg.success || response.message ) );
						__callback( callback.success, response, data );

					} else {

						__message(
							action,
							'error',
							( msg.error || response.message || JS_TRANSLATIONS.genericErrorMsg )
						);
						__callback( callback.error, response, data );

					}
				},

				error: function () {
					__message( action, 'error', ( msg.error || JS_TRANSLATIONS.genericErrorMsg) );
					__callback( callback.error, null, data );

				},

				complete: function () {
					__callback( callback.complete );
				}
			} );
		} else if ( DEBUG ) {
			console.log( type + ' request FAILED', 'action:', action, 'data:', data );
		}
	}


	/**
	 *
	 * @param {function=} cb
	 * @param {Object=}   response
	 * @param {Object=}   request
	 * @private
	 */
	var __callback = function ( cb, response, request ) {
		if ( _.isFunction( cb ) ) {
			cb( response, request );
		}
	};

    /**
     *
     * @param {string}  action
     * @param {string=} type
     * @param {string=} msg
     * @private
     */
    var __displayMsg = function ( action, type, msg ) {
        $( document ).trigger(
            'zg-' + type || 'info',
            [{
                eventType: 'zgPost.' + action,
                message:   msg
            }]
        );
    };


	/**
	 *
	 * @param {string}  action
	 * @param {string=} type
	 * @param {string=} msg
	 * @private
	 */
	var __message = function ( action, type, msg ) {
        if( _.isArray(msg) ){
            _.each(msg, function(note) {
                __displayMsg( action, type, note );
            });
        }
        else {
            __displayMsg( action, type, msg );
        }
	};

	// ----------------------------------------------------------------------------------------------------------------

	/**
	 *
	 * @param {string}  action
	 * @param {Object=} data
	 * @param {Object=} msg
	 * @param {Object=} callback
	 */
	root.zgPost = function ( action, data, msg, callback, dataType ) {
		__request( 'POST', action, data || {}, msg || {}, callback || {}, dataType );
	};

	/**
	 *
	 * @param {string}  action
	 * @param {Object=} data
	 * @param {Object=} msg
	 * @param {Object=} callback
	 */
	root.zgGet = function ( action, data, msg, callback, dataType ) {
		__request( 'GET', action, data || {}, msg || {}, callback || {}, dataType );
	};

}.call( this, jQuery, _ ));
