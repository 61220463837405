/**
 *
 * Manage autocomplete search
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * DISCLAIMER:
 *   I just re-wrote this.
 *   I'm not sure about what half of the input fields are
 *   supposed to do or why are we spliting the product name
 *   in " - "
 *
 */

(function ( $ ) { "use strict";

	/**
	 * @selector data-zg-role="product-finder" The plugin start if there is the selector (form) in the dom when the page load
	 */

	var toggle              = '[data-zg-role="product-finder"]';
	var toggle_search_input = '[data-zg-role="search-input"]';
	var toggle_search_type  = '[data-zg-role="search-type"]';
	var toggle_search_id    = '[data-zg-role="search-id"]';
	var toggle_product_id   = '[data-zg-role="search-product_id"]';
	var toggle_product_name = '[data-zg-role="search-product_name"]';

	var ZG_Search = function ( element, config ) {
		var self = this;

		this.init = function ( element, config ) {
			var $element       = $(element);
			self.$element      = $element;
			self.config        = $.extend( {}, config, self.$element.data('config') || {} ) ;
			self.$eventBus     = $(document);

			self.$search_input = $element.find( toggle_search_input );

			self.$element.on("submit", self.searchProducts);
			self.startTypeAhead();
		};

		this.select = function() {
			var val = this.$menu.find('.active').attr('data-value');
			if (typeof val !== 'undefined') {
				this.$element
						.val(this.updater(val))
						.change();
						
				if (this.ajax && this.ajax.loadProductPageBy!=null){
						var id = this.$menu.find('.active').attr('data-id');
						var type = this.$menu.find('.active').attr('data-type');
						if( id > 0 ){
							this.$element.attr('data-id',id);
							this.$element.attr('data-type',type);
							
							if(type == 'product'){
								this.$element.attr('data-value',this.$menu.find('.active').attr('data-value'));
							}
						} else {
							this.$element.val(this.query)
						}
					this.$element.closest( toggle ).submit();	
				}
			}
			return this.hide()
		}

		this.startTypeAhead = function() {
			var url = window.makeUrl({module: 'eshop', action: 'autoComplete'});

			self.$search_input.typeahead({
				ajax: {
					url: url,
					preDispatch: function (query) {
						return {
							action: 'autoComplete',
							key: query,
							limit:20,
							orderBy : 'name',
							sortOrder : 'ASC'
						};
					},
					dType : 'type',
					displayField : 'value',
					loadProductPageBy : 'product_id'
				},
				sorter : 'none',
				items  : 20,
				select: self.select,
			});
		}

		this.searchProducts = function() {
			var productName = self.$search_input.val();
			if ( !productName || productName==="" ) return false;

			var type = self.$search_input.data('type') || "";
			var id   = self.$search_input.data('id')   || "";

			// we are getting here only once. we don't need to add this to the "self" object
			self.$element.find( toggle_search_type ).val( type );
			self.$element.find( toggle_search_id ).val( id );
			self.$element.find( toggle_product_id ).val( id );
			self.$element.find( toggle_product_name ).val( productName.split(" - ")[0] );

			self.$element.find(".characteristic-key").val(productName);
		}

		this.init( element, config );
	};

	$.fn.ZG_search = function ( config ) {
		return this.each( function () {
			var $this = $(this);
			var data = $this.data('zgSearch');
			if (!data) {
				$this.data('zgSearch', (data = new ZG_Search( this, config )) );
			}
		});
	};

	// Initilize Script
	$(toggle).ZG_search( window.ZG_CONFIG );
})( jQuery );