// add your site specific javascript here
(function ( $ ) {
	'use strict';

	$.ajaxSetup( {
		cache: true
	} );

	//For crawler
	if ( !window.JS_ENABLED ) {
		$.ajax( {
			url:      window.makeUrl( { module: 'eshop', action: 'setJavascriptEnabled' } ),
			type:     'post',
			dataType: 'json'
		} );
	}

	// -----------------------------------------------------------------------------------------------------------------
	// Anything to be initialized on document ready goes here

	$( function () {
		//Main product image left/right
		var $gallery = $('#gallery');
		$gallery.find('a').click(function() {
			if ( $(this).parent('div').prev().find('a').length == 0 ) {
				$('[data-zg-role="zoom-left"]').addClass('disabled');
			} else {
				$('[data-zg-role="zoom-left"]').removeClass('disabled');
			}
			if ( $(this).parent('div').next().find('a').length == 0 ) {
				$('[data-zg-role="zoom-right"]').addClass('disabled');
			} else {
				$('[data-zg-role="zoom-right"]').removeClass('disabled');
			}
		});
		$('[data-zg-role="zoom-left"]').click(function() {
			var $prev = $gallery.find('.active').parent('div').prev().find('a');
			$prev.click();
			if ( $gallery.find('.active').parent('div').prev().find('a').length == 0 ) {
				$(this).addClass('disabled');
			}
			$('[data-zg-role="zoom-right"]').removeClass('disabled');
		});
		$('[data-zg-role="zoom-right"]').click(function() {
			var $next = $gallery.find('.active').parent('div').next().find('a');
			$next.click();
			if ( $gallery.find('.active').parent('div').next().find('a').length == 0 ) {
				$(this).addClass('disabled');
			}
			$('[data-zg-role="zoom-left"]').removeClass('disabled');
		});
		
		//Increasing-decreasing qty input
		$( '[data-zg-role="change-qty"]' ).click( function(event) {
			event.preventDefault();
			var type = $( this ).data( 'type' );
			var $inputQtySibling = $( this ).parent().siblings( '[data-zg-role="quantity"]' );
			var maxQty = $inputQtySibling.data( 'max' );
			var floatQty = $inputQtySibling.data( 'floatQty' );
			var qty = $inputQtySibling.val();
			var step;

			if( floatQty ){
					step = 0.01;
			}
			else {
					step = 1;
			}
			qty = Number(qty);

			$( '[data-zg-role="change-qty"]' ).attr( 'disabled', false );
			if( type == "minus" ){
				//If qty is 0, we will disable the current minus icon. 
				//The next time this function will be run, all the change qty icons will be enabled again.
				if( qty == 0 ){
					$( this ).attr( 'disabled', true );
				}
				else{
					qty = qty - step;
				}
			}
			else {
				//If qty is equal to maxQty, we will disable the current plus icon. 
				//The next time this function will be run, all the change qty icons will be enabled again.
				if( qty == maxQty ){
						$( this ).attr( 'disabled', true );
				}
				else {
					qty = qty + step;
				}
			}

			$( this ).parent().siblings( '[data-zg-role="quantity"]' ).val( qty );
		});
		
		$('.studioworx').hide();
		
		var slickConfig = {
			infinite: false,
  		slidesToShow: 3,
  		slidesToScroll: 1,
			prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-caret-left"></i></button>',
			nextArrow: '<button type="button" class="slick-next"><i class="fa fa-caret-right"></i></button>',
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1
					}
				}
			]
		};
		var $measurements = $('.measurements');
		if ($measurements.length) {
			$measurements.slick(slickConfig);
			$measurements.find('.next').click(function(event) {
				event.preventDefault();
				$measurements.slick('slickNext');
			});
		}
		
		$('#review-form').click(function(event) {
			event.preventDefault();
			$measurements.slick('unslick');
			$(this).hide();
			$('#sendEmailSubmit').show();
		});

		$('#frmPhoneSend #sendEmailSubmit').click(function() {
			var ticket_note = "";
			$('#frmPhoneSend .measurements input').each(function() {
				ticket_note = ticket_note + $(this).attr('name') + ": " + $(this).val() + $('#unit').val();
				ticket_note = ticket_note + " / " + ($('#unit').val()=="cm" ? Math.round($(this).val()/2.54*100)/100 : Math.round($(this).val()*2.54*100)/100) + ($('#unit').val()=="cm" ? "in" : "cm");
				ticket_note = ticket_note + "\n";
			});
			$('#note').val(ticket_note);
		});
		
		$('#switch-unit').click(function(event) {
			event.preventDefault();
			if ( $(this).data('unit') == 'cm' ) {
				$(this).data('unit', 'in');
				$(this).html($(this).data('to-cm'));
				$measurements.find('.input-group-addon').html("in");
				$measurements.find('.form-control').each(function() {
					var val = $(this).val();
					val = Math.round(val / 2.54 * 100) / 100;
					$(this).val(val);
				});
				$('#unit').val('in');
			} else {
				$(this).data('unit', 'cm');
				$(this).html($(this).data('to-inch'));
				$measurements.find('.input-group-addon').html("cm");
				$measurements.find('.form-control').each(function() {
					var val = $(this).val();
					val = Math.round(val * 2.54 * 100) / 100;
					$(this).val(val);
				});
				$('#unit').val('cm');
			}
		});
		
		if ( $('[data-id="356953"]').length || $('[data-id="356954"]').length || $('[data-id="356289"]').length  ) {
			$(document).on('zg.product.ready',function(){
				$('[data-zg-role="option-selector"] option').sortElements(function(a,b) {
					var aval = $(a).text().match(/\d+/g);
					if ( aval ) aval = aval[0];
					else aval = 0;
					var bval = $(b).text().match(/\d+/g);
					if ( bval ) bval = bval[0];
					else bval = 0;
					return parseInt(aval) > parseInt(bval) ? 1 : -1;
				});
			});
		}

		//
		// Last Viewed Product
		// ------------------------------

		if ($("body").hasClass('product')) {
			var product_code = $('.code').html();
			$.cookie("last_viewed_product", product_code, { path: '/' });
		}

		var last_viewed_product = $.cookie("last_viewed_product");
		var $last_viewed_product_field = $('[name="last_viewed_product"]');
		if ($last_viewed_product_field.length && last_viewed_product) {
			$last_viewed_product_field.val(last_viewed_product);
		}
		
		//
		// Generic Modal
		// ------------------------------
		
		function genericPopupTimer(timeout, id) {
			var timeFromFirstVisit = Date.now() - $.cookie("generic-cookie-" + id);
			var shown = false;
			$('[data-zg-role="generic-modal"]').each(function() {
				if ($(this).hasClass('in')) shown = true;
			});
			if ( timeFromFirstVisit > timeout && shown == false ) {
				$.cookie("generic-cookie-" + id, 9999999999999, { path: '/' });
				if ( $("#generic_modal_" + id) ) $("#generic_modal_" + id).modal();
			} else {
				setTimeout(function() {
					genericPopupTimer(timeout-1000, id);
				}, 1000);
			}
		};
		
		$('[data-zg-role="generic-modal"]').each(function() {
			var id = $(this).data('id');
			var timeout = $(this).data('timeout');
			var firstVisit = $.cookie("generic-cookie-" + id);
			if ( !firstVisit ) {
				firstVisit = Date.now();
				$.cookie("generic-cookie-" + id, firstVisit, { path: '/' });
			}
			setTimeout(function() {
				genericPopupTimer(timeout, id);
			}, 1000);
		});
		
		//
		// Plus / Minus
		// ------------------------------
		
		var $minus = $('#voucher_minus');
		var $qty = $('#voucher_amount');
		var $plus = $('#voucher_plus');
		
		$minus.click(function(event){
				event.preventDefault();
				var qty = $qty.val();
				qty = parseInt(qty);
				if (qty > 30) $qty.val(qty-10);
				else $qty.val(20);
		});

		$plus.click(function(event){
				event.preventDefault();
				var qty = $qty.val();
				qty = parseInt(qty);
				if (qty <= 10) $qty.val(20);
				else if (qty <= 490) $qty.val(qty+10);
				else if (!qty) $qty.val(20);
		});
		
		//
		// Out of stock
		// ------------------------------
		
		$(document).on('product-updated-availability', function(){
			if ( $('[data-zg-role="availability"]').html() != "Please select the options for display the availability" && $('[data-zg-role="add-to-cart"]').prop('disabled') ) $('.out-of-stock').show().next('hr').hide();
			else $('.out-of-stock').hide().next('hr').show();
		});

		//
		// Personalisation
		// ------------------------------
		
		$('.button-colour input').change(function(){
			var value = $(this).val();
			$('#button-colour-input').val(value);
		});
		
		$('.personalise-it .checkbox label span').click(function(){
			if ( $(this).parent().hasClass('disabled') ) $(this).parent().removeClass('disabled');
			else $(this).parent().addClass('disabled');
			$('+ input', this).val('');
		});
		
		$(document).on('zg.product.ready', function(product){
			$('.button-colour input', product).change(function(){
				var value = $(this).val();
				$('#button-colour-input').val(value);
			});
			$('.personalise-it .checkbox label span', product).click(function(){
				if ( $(this).parent().hasClass('disabled') ) $(this).parent().removeClass('disabled');
				else $(this).parent().addClass('disabled');
				$('+ input', this).val('');
			});
		});
		
		//
		// Size Guide Conversion
		// ------------------------------
		
		$('.size-chart-modal').each(function() {
			var self = this;
			$('.unit-conversion input', this).click(function() {
				var val = $(this).val();
				if ( val == 'inch' ) $('.table tr.convertible td', self).each(function() {
					var cm = $(this).html();
					var hascm = $(this).hasClass('cm');
					if ( cm && !isNaN(cm) && hascm ) {
						var inch = Math.round(cm / 2.54 * 100) / 100;
						$(this).addClass('in').removeClass('cm').html(inch);
					}
				});
				else $('.table tr.convertible td', self).each(function() {
					var cm = $(this).addClass('cm').removeClass('in').data('default');
					$(this).html(cm);
				});
			});
		}); 
		
		//
		// FAQ
		// ------------------------------
		
		$('.faq .question').click(function(){
			var active = $(this).hasClass('active');
			$('.faq .question').removeClass('active');
			if ( !active ) $(this).addClass('active');
		});
		
		//
		// Reviews
		// ------------------------------
		
		$('.product-details .average-rating .reviews').click(function(event){
			event.preventDefault();
			var scrollTo = $('#product-description').offset().top - 64;
			$("html, body").animate({ scrollTop: scrollTo }, 400);
			$('#product-description .nav-tabs a[href="#reviews"]').click();
		});
		
		//
		// Sorter Touch
		// ------------------------------
		
		$('.sort-by ul').click(function(event){
			if ( !$(this).hasClass('active') ) {
				event.preventDefault();
				event.stopPropagation();
				$(this).addClass('active');
			}
		});
		$('body').click(function(event){
			$('.sort-by ul').removeClass('active');
		});
		
		//
		// Blog Post Preview
		// ------------------------------
		
		$('.post-preview p').dotdotdot({
			height: 44,
			watch: true
		});
		
		$('.post-preview .title').dotdotdot({
			height: 26,
			watch: true
		});
		
		$(document).on('zg.getProductInfo.productCreated',function(e, element, options, product){
			$('.name', element).dotdotdot({
				height: 40,
				watch: true
			});
		});
		
		//
		// Sticky Menu
		// ------------------------------
		
		var headerMenuTop = 103;
	
		function sticky() {
			var top = $(window).scrollTop();
			if ( top >= headerMenuTop ) $('#site_header, body').addClass('sticky');
			else $('#site_header, body').removeClass('sticky');
		}
		
		$(window).scroll(function(){
			sticky();
		});
		sticky();
		
		//
		// Header Menu Touch
		// ------------------------------
		
		$('.touchevents #site_header .menu > li.has-children > a').click(function(event){
			event.preventDefault();
			event.stopPropagation();
			$(this).parent().siblings().removeClass('active');
			$(this).parent().toggleClass('active');
			$('#site_dropdown_overlay').toggle();
		});
		$('.no-touchevents #site_header .menu > li.has-children').hover(function(event){
			$('#site_dropdown_overlay').show();
		},function(event){
			$('#site_dropdown_overlay').hide();
		});
		$('.touchevents body').click(function(event){
			$('#site_header .menu > li.has-children').removeClass('active');
			$('.dropdown-overlay').hide();
		});
		
		//
		// Mobile menu toggle
		// ------------------------------
		
		$('.mobile-menu-toggle').click(function(event){
			event.preventDefault();
			$('html').toggleClass('mobile-menu-open');
		});
		
		//
		// Submenu dropdown image changer
		// ------------------------------
		
		$('#site_header_menu .submenus').each(function(){
			var that = $(this);
			$('.categories a', this).hover(function(){
				var id = $(this).attr('id');
				id = id.split('-')[1];
				if ( $('#image-' + id, that).length ) { 
					$('.active', that).removeClass('active');
					$('#image-' + id, that).addClass('active');
				}
			});
		});
		
		//
		// Mobile menu accordion
		// ------------------------------
		
		$('#site_mobile_menu .menu .has-children > a').click(function(event){
			event.preventDefault();
			$(this).parent().siblings().removeClass('active');
			$(this).parent().toggleClass('active');
		});
		
		//
		// Footer links mobile toggler
		// ------------------------------
		
		$('.links-toggle').click(function(event){
			$(this).toggleClass('active').siblings('.links').slideToggle();
		});
		
		//
		// Homepage slider
		// ------------------------------
		
		$('.component-slider').on('init', function(event, slick){
			$(this).show().next('.component-slider-loading').hide();
		}).slick({
			dots: false,
			infinite: true,
			arrows: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
  		autoplaySpeed: 3000
		});
		
		//
		// Other
		// ------------------------------
		
		//First modal registration
		$( '#registrationModalThanks' ).modal( 'show' );

		// If we have an "active" tab we start with that one selected, otherwise we select the first one
		$( '.nav-tabs' ).each( function () {
			var $activeTab = $( this ).find( '.active [data-toggle="tab"]:first' );

			if ( $activeTab.length ) {
				$activeTab.tab();
			} else {
				$( this ).find( '[data-toggle="tab"]:first' ).tab();
			}
		} );

		// Tooltips
		//$('[data-toggle="tooltip"]').tooltip();

		// Carousel Settings - Home
		$( '.carousel' ).carousel( {} );

        // SWIPE CAROUSEL
        var $carousel = $( '.carousel' );
        if ( $carousel.length ) {
            $carousel.swiperight( function () {
                $( this ).carousel( 'prev' );
            } );
            $carousel.swipeleft( function () {
                $( this ).carousel( 'next' );
            } );
        }
	} );

	$( function () {
		$( this ).find( '[data-zg-role="search-input"]').each( function(){
				//Bind the search function
				$( this ).on('input paste', function(){


					// Retrieve the input field text and reset the count to zero
					var filter = $(this).val(), count = 0;

					// Loop through the comment list
					$('[data-zg-role="search-items"]').each(function(){

						// If the list item does not contain the text phrase fade it out
						if ($(this).text().search(new RegExp(filter, "i")) < 0) {
							$(this).fadeOut();

							// Show the list item if the phrase matches and increase the count by 1
						} else {
							$(this).show();
							//if is the first visible I also show the function definition
							/*if(count == 0){
								console.log($(this).find('a'));
							}*/

							count++;
						}
					});

					// Update the count
					if(count > 0){
						$("#noResult").addClass('hide');
					}
					else{
						$("#noResult").removeClass('hide');
					}
					$("#filter-count").text(count);
					$(".count-hide").removeClass('hide');

				});
			}
		);

	});

	//Scroll slowly to the target
	$( function () {
		$('[data-zg-scrollTo="slowly"]').click(function () {
			var target = $(this).data('zg-target'),
				href = $(this).attr("href");
			$('html, body').animate({scrollTop: $(target).offset().top}, 1000);
			window.location.hash = href;
		});
	});


	// Redirecting to the "Messages" tab in the My Acoount page when the user clicks on "View my messages"
	if ( SGL_JS_MANAGER === 'account' && window.location.hash === '#tickets' ) {
		$( '#tabs-account li a[href="#tickets"]' ).tab( 'show' );
	}


	// Fading out the bouncing arrow for the Home Page slider
	$( window ).scroll( function(){
		$(".arrow").css("opacity", 1 - $(window).scrollTop() / 250);
		//250 is fade pixels
	});

	$( '#btn-search' ).click( function(){
		$( '#searchContainer').toggle( '200' );
	})

})( jQuery );