/**
 * Plugin for create pagination in category page
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 */

/* global handlebarsTemplates */

(function ( $, _ ) {
	'use strict';

	/**
	 * @selector data-zg-role="pagination" The plugin start if there is the selector in the dom when the page load
	 */

	var selector = '[data-zg-role="pagination"]';

	/**
	 * @param {string} [elementContainer] target element where the items will be added. if it's empty it will try to use the current element
	 * @param {string} [elementControls] controls container for the normal pagination
	 * @param {string} [elementControlCurrent] element contain current page
	 * @param {string} [elementControlNext] li contains link for next page
	 * @param {string} [elementControlPrev] li contains link for prev page
	 * @param {string} [elementControlLimit
	 * @param {string} [elementControlScroller] infinite scroll controller (loading!)
	 * @param {string} [elementCounter]
	 * @param {string} [elementSorting]
	 * @param {boolean} [infiniteScrolling]
	 * @param {int} [pageInit]
	 * @param {int} [pageItems]
	 * @param {int} [delay] delay between rendering each item
	 * @param {string} [template] handlebars template to create the items
	 * @param {boolean} [updateUri]
	 * @param {boolean} [isFirstLoad] do not update url at first load
	 * @param {boolean} [sortItemsBy]
	 * @param {boolean} [sortItemsPattern]
	 */

	var DEFAULTS = {
		elementContainer:       '[data-zg-role="pagination-container"]',
		elementControls:        '[data-zg-role="pagination-controls"]',
		elementControlCurrent:  '[data-zg-role="pagination-current"]',
		elementControlNext:     '[data-zg-role="pagination-next"]',
		elementControlPrev:     '[data-zg-role="pagination-prev"]',
		elementControlLimit:    '[data-zg-role="pagination-limit"]',

		elementControlScroller: '[data-zg-role="pagination-scroller"]',

		elementCounter: '[data-zg-role="pagination-counter"]',
		elementSorting: '[data-zg-role="pagination-sort"]',

		infiniteScrolling: true,

		pageInit:  1,
		pageItems: 0,

		delay: 100,

		template: null,

		updateUri:   false,
		isFirstLoad: true,

		sortItemsBy:      false,
		sortItemsPattern: null
	};

	// PAGINATION CLASS DEFINITION
	// ===========================

	/**
	 *
	 * @param {HTMLElement} element
	 * @param {!object}     options
	 *
	 * @constructor
	 */
	var Pagination = function ( element, options ) {
		this.$element = $( element );

		this.options = _.clone( DEFAULTS );

		this.collection   = [];
		this.collectionId = null;

		this.scroll          = 0;
		this.stopScrollEvent = true;

		this.sorting = {};

		this.updateOptions( options );

		this.uriComponents = ['limit', 'page'];

		this.limit = +(this.options.pageItems);
		this.page  = +(this.options.pageInit);

		this.$container       = $( this.options.elementContainer, this.$element );
		this.$controls        = $( this.options.elementControls, this.$element );
		this.$controlCurrent  = $( this.options.elementControlCurrent, this.$element );
		this.$controlNext     = $( this.options.elementControlNext, this.$element );
		this.$controlPrev     = $( this.options.elementControlPrev, this.$element );
		this.$controlLimit    = $( this.options.elementControlLimit, this.$element );
		this.$controlScroller = $( this.options.elementControlScroller, this.$element );
		this.$counter         = $( this.options.elementCounter, this.$element );

		this.setEventHandlers();
	};


	/**
	 * Hide the controls
	 *
	 */
	Pagination.prototype.disableControls = function () {
		if ( this.options.infiniteScrolling ) {
			this.stopScrollEvent = true;
			this.$controls.hide();

			if ( this.page === 1 ) {
				this.$controlScroller.hide();
			} else {
				this.$controlScroller.addClass( 'loading' );
			}
		} else {
			this.$controls.hide();
			this.$controlScroller.hide();

			// set the container as 'loading'
			this.$element.addClass( 'loading' );
		}
	};


	/**
	 * Update and show the controls
	 *
	 */
	Pagination.prototype.enableControls = function () {
		var maxPages = this.getMaxPages();

		if ( this.options.infiniteScrolling ) {
			// controller for infinite scrolling

			// if the current page is not the last one we display the loader
			if ( this.page < maxPages ) {
				this.$controlScroller
					.removeClass( 'hidden' )
					.removeClass( 'loading' )
					.fadeIn();
			} else {
				this.$controlScroller.addClass( 'hidden' );
			}

			// re-enable scroll controller
			this.stopScrollEvent = false;

			// trigger scrolling again. This will render the next page if, after the current one is finished rendering,
			// the $controlScroller element is still visible;
			$( window ).trigger( 'scroll.zg.pagination.loader' );

		} else if ( maxPages > 1 ) {
			// controllers for normal pagination.
			// If there is only one page we won't display the controls, so we don't bother to update them

			// update 'current page' control display
			this.$controlCurrent.text( this.page + ' / ' + maxPages );

			// enable/disable 'next' and 'previous' controls depending on current page
			if ( this.page === 1 ) {
				this.$controlPrev.addClass( 'disabled' );
			} else {
				this.$controlPrev.removeClass( 'disabled' );
			}

			if ( this.page === maxPages ) {
				this.$controlNext.addClass( 'disabled' );
			} else {
				this.$controlNext.removeClass( 'disabled' );
			}

			this.$controls.removeClass( 'hidden' ).fadeIn();
		}

		this.$element.removeClass( 'loading no-js-content' );
	};


	/**
	 *
	 */
	Pagination.prototype.getMaxPages = function () {
		var limit = parseInt( this.limit, 10 ),
			max   = 1;

		if ( !isNaN( limit ) && limit > 0 ) {
			max = Math.ceil( ( this.collection && this.collection.length || 1 ) / ( limit ) );
		}

		return max;
	};


	/**
	 *
	 */
	Pagination.prototype.renderCollection = function () {
		var displayed = 0,
			delay     = 0,
			i,
			that = this;

		this.disableControls();

		// only remove the previous elements is infinite scrolling is not enabled or the page is 1
		if ( !this.options.infiniteScrolling || this.page === 1 ) {
			this.$container.empty();
		}

		if ( !_.isEmpty( this.collection ) ) {
			for (
				i = ( this.limit * (this.page - 1));
				i < _.size(this.collection) && ( !this.limit || displayed < this.limit );
				i++
			) {
				displayed++;

				delay += this.options.delay;

				this.renderItem( this.collection[i], delay, this.collectionId );
			}
		}

		setTimeout(
			function () {
				that.enableControls();
			},
			( this.options.infiniteScrolling ? delay : this.options.delay )
		);

		if ( displayed ) {
			this.$container.removeClass( 'is-empty' );
		} else {
			this.$container.addClass( 'is-empty' );
		}
	};


	/**
	 *
	 * @param {object}  item
	 * @param {number=} delay
	 * @param {string}  collectionId
	 */
	Pagination.prototype.renderItem = function ( item, delay, collectionId ) {
		var $item,
			that = this,
			template = that.options.template;

		setTimeout(
			function () {

				if ( that.collectionId === collectionId ) {

					// Changing the template name when is a "featured product"
					if( item.text_block || item.image_block){
						template = "featured_product";
					}

					// update the contents
					for ( var i in that.options.prodHighlight) {
						if( that.options.prodHighlight[i].fields.product_id == item.id){

							if( that.options.filtersSelected == false || (that.options.prodHighlight[i].fields.show_with_filter && that.options.prodHighlight[i].fields.show_with_filter[0] == '1' && that.options.filtersSelected == true)){
								if(that.options.prodHighlight[i].fields.template){
									template = that.options.prodHighlight[i].fields.template;
								}else{
									template = "preview-product-highlight";
								}

								$.extend( item, {cms : that.options.prodHighlight[i]} );
							}


						}
					}

					console.log(template);

					$item = $( handlebarsTemplates.render( template, item ) );
					that.$container.append( $item );

					setTimeout(
						function () {
							$item.addClass( 'in' ); // Fade-in in using bootstrap classes.

							$( document ).trigger( 'zg.getProductInfo.productCreated', [$item, null, item] );
							$( document ).trigger( 'zg.pagination.itemCreated', [$item, null, item] );
						},

						(that.options.delay || 0) / 2
					);
				}
			},

			(+delay || 0)
		);
	};


	/**
	 * get the current sorting from the response
	 *
	 */
	Pagination.prototype.setDefaultSorting = function () {
		var attr, pattern;

		this.sorting.normal = null;

		if ( this.collection && this.collection.length ) {
			if ( this.collection[0].hasOwnProperty( 'id' ) ) {
				attr = 'id';
			} else if ( this.collection[0].hasOwnProperty( 'product_id' ) ) {
				attr = 'name';
			} else if ( this.collection[0].hasOwnProperty( 'name' ) ) {
				attr = 'name';
			}

			if ( attr ) {
				pattern = _.uniq( _.map( this.collection, function ( obj ) {
					return ('' + obj[attr]);
				} ) );

				this.setSorting( 'normal', attr, pattern );
			}
		}
	};


	/**
	 *
	 */
	Pagination.prototype.setEventHandlers = function () {
		var that = this;

		this.$element.on( 'zg.filters.updateItems', function ( e, collection ) {
			that.updateCollection( collection );
		} );

		// ---------------------------------------------------------------------

		if ( this.options.infiniteScrolling ) {

			// controller for infinite scrolling
			$( window ).on( 'scroll.zg.pagination.loader', function () {
				var currentScroll         = $( this ).scrollTop(),
					controlScrollerOffset = that.$controlScroller.offset().top - $( this ).height();

				if (
					!that.stopScrollEvent &&
					currentScroll >= that.scroll && // we are scrolling down
					that.page < that.getMaxPages() && // we are not in the last page
					that.$controlScroller.is( ':visible' ) && // the controller is visible
					currentScroll >= controlScrollerOffset // is displayed in the screen
				) {
					that.stopScrollEvent = true;
					that.page++;
					that.updateCurrentPage();
				}

				that.scroll = currentScroll;
			} );
		}

		// ---------------------------------------------------------------------
		// controllers for normal pagination

		// load next page
		this.$controlNext.on( 'click.zg.pagination.next', function ( e ) {
			e.preventDefault();

			if (
				!$( this ).is( '.disabled' ) &&
				that.page < that.getMaxPages()
			) {
				that.page++;
				that.updateCurrentPage();
			}
		} );

		this.$controlPrev.on( 'click.zg.pagination.prev', function ( e ) {
			e.preventDefault();

			if (
				!$( this ).is( '.disabled' ) &&
				that.page > 1
			) {
				that.page--;
				that.updateCurrentPage();
			}
		} );

		// ---------------------------------------------------------------------

		this.$controlLimit.on( 'click.zg.pagination.limit', function ( e ) {
			var $this = $( this ),
				limit = +( $this.data( 'limit' ) );

			e.preventDefault();

			if ( !$this.is( '.disabled' ) && _.isNumber( limit ) ) {
				that.limit = limit;
				that.updateCurrentPage( 1 );
			}
		} );

		// ---------------------------------------------------------------------

		this.$element.on( 'click.zg.pagination.sort', this.options.elementSorting + ' [data-sort-by]', function ( e ) {
			var $this = $( this );

			e.preventDefault();

			that.$element.find( that.options.elementSorting ).removeClass( 'active' );
			$this.closest( that.options.elementSorting ).addClass( 'active' );

			that.updateSorting( $this.data( 'sort-by' ) || false, $this.data( 'sort-pattern' ) || null, $this.data( 'dir' ) );
			that.updateCurrentPage( 1 );
		} );

		// ---------------------------------------------------------------------

		$( document ).on( 'zg.urimgr.updatedUri', function ( e, data ) {
			that.options.isFirstLoad = false;
			that.updateStatus( data.components );
		} );
	};


	/**
	 *
	 */
	Pagination.prototype.setSorting = function ( key, attr, pattern, dir ) {
		this.sorting[key] = {
			'attr':    attr,
			'pattern': pattern || null,
            'dir': dir || 1
		};
	};


	/**
	 *
	 */
	Pagination.prototype.sortCollection = function () {
		var primary, secondary;

		if ( this.collection && this.collection.length && this.sorting.primary ) {
			primary   = this.sorting.primary;
			secondary = this.sorting.secondary|| this.sorting.normal || { attr: 'splitValue' };

			this.collection.sort( window.zg_sortElements( primary, secondary ) );
		}
	};


	/**
	 *
	 */
	Pagination.prototype.updateCollection = function ( collection ) {
		var page;

		this.collection   = collection || [];
		this.collectionId = _.uniqueId('pagination_');

		this.updateCounter(); // show the number of elements

		this.setDefaultSorting();
		this.sortCollection();

		// Infinite scrolling is going to always start from the first page
		page = this.options.infiniteScrolling ? 1 : this.validatePageNumber( this.options.pageInit );

        // Adding the "featured products" elements to the collection only during the first page paginated
        if( page === 1 ){
            var content_category_id = $('#featured_products_info').data('categoryId');
            var content_featured_blocks = $('#featured_products_info').data('featuredBlocks');
            if( !_.isEmpty(content_featured_blocks) ){
                var current_category = this.options.categoryId;

                if( content_category_id == current_category){
                    _.each( content_featured_blocks, function( info ) {
                        var featured_block = {};
                        featured_block.text_block = info.text || "";
                        featured_block.image_block = info.image || "";
                        collection.splice( info.position-1, 0, featured_block );
                    });
                }
            }
        }

		// render the initial page
		this.updateCurrentPage( page );

		// set scrolling as 0 and trigger a scroll event when the collection is updated
		if ( this.options.infiniteScrolling ) {
			this.scroll = 0;
			$( window ).scroll();
		}

		// the initial collection has been rendered.
		// subsequent collections will start from the first page
		this.options.pageInit = 1;
	};


	/**
	 * show / update the items counter
	 *
	 */
	Pagination.prototype.updateCounter = function () {
		this.$counter
			.parent()
			.removeClass( 'hidden' );

		this.$counter
			.hide()
			.text( this.collection.length )
			.fadeIn();
	};


	/**
	 *
	 */
	Pagination.prototype.updateCurrentPage = function ( page, stopUriUpdate, replaceUri ) {
		this.page = this.validatePageNumber( page );

		// render the elements
		this.renderCollection();

		// update the url
		if ( !stopUriUpdate ) {
			this.updateURL( replaceUri );
		}

		// Not the first load anymore
		this.options.isFirstLoad = false;

		$( document ).trigger( 'zg.pagination.pageRendered' );
	};


	/**
	 *
	 * @param {Object=} options
	 */
	Pagination.prototype.updateOptions = function ( options ) {
		_.extendOwn( this.options, options || {} );

		this.updateSorting( this.options.sortItemsBy, this.options.sortItemsPattern );
	};


	/**
	 *
	 * @param attr
	 * @param pattern
	 */
	Pagination.prototype.updateSorting = function ( attr, pattern, dir ) {
		if ( attr === false ) {
			this.sorting.primary   = null;
			this.sorting.secondary = null;
		} else {
			if ( this.sorting.primary ) {
				this.setSorting( 'secondary', this.sorting.primary.attr, this.sorting.primary.pattern || null, dir );
			}

			this.setSorting( 'primary', attr, pattern, dir );
		}

		this.sortCollection();
	};


	/**
	 * Update the pagination status (items per page, current page) from a history event
	 *
	 * @param {object} status
	 */
	Pagination.prototype.updateStatus = function ( status ) {
		var i, component, update = false;

		status = status || {}; // just in case

		for ( i = 0; i < this.uriComponents.length && !update; i++ ) {
			component = this.uriComponents[i];

			if (
				status.hasOwnProperty( component ) && !_.isEqual( +(this[component]), +(status[component]) )
			) {
				this[component] = +(status[component]);
				update          = true;
			}
		}

		if ( update ) {
			this.updateCurrentPage( status.page, true );
		}
	};


	/**
	 * Update the URL with the current page and limit
	 *
	 * @param {boolean} replaceUri
	 */
	Pagination.prototype.updateURL = function ( replaceUri ) {
		var applied;

		if ( this.options.updateUri && !this.options.isFirstLoad ) {
			applied = {
				'limit': (this.options.pageItems === this.limit ? null : this.limit),
				'page':  ((this.options.infiniteScrolling || this.page === 1) ? null : this.page)
			};

			$.uriMgr( {
				'action':    (replaceUri ? 'replace' : 'push'),
				'applied':   applied,
				'available': this.uriComponents
			} );
		}
	};


	/**
	 * makes sure the page is between the acceptable range
	 *
	 * @param {number} page
	 *
	 * @returns {number}
	 */
	Pagination.prototype.validatePageNumber = function ( page ) {
		var current;

		if ( page && !isNaN( page ) ) {
			current = +page;
		} else {
			current = this.page || 1;
		}

		current = Math.min( current, this.getMaxPages() );
		current = Math.max( current, 1 );

		return current;
	};


	// PAGINATION PLUGIN DEFINITION
	// ============================

	/**
	 *
	 * @param {object=} option
	 * @param {Array=}  collection
	 *
	 * @returns {object}
	 */
	function Plugin ( option, collection ) {
		return this.each( function () {
			var $this   = $( this ),
				data    = $this.data( 'zg.pagination' ),
				options = $.extend( {}, window.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option ),
				items   = collection || options.collection || null;

			if ( !data ) {
				$this.data( 'zg.pagination', ( data = new Pagination( this, options ) ) );
			} else if ( typeof option === 'object' ) {
				data.updateOptions( option );
			}

			if ( items ) {
				data.updateCollection( items );
			}
		} );
	}

	$.fn.zg_pagination             = Plugin;
	$.fn.zg_pagination.Constructor = Pagination;


	// PAGINATION DATA-API
	// ===================

	$( function () {
		$( selector ).each( function () {
			Plugin.call( $( this ) );
		} );
	} );

}( jQuery, _ ));
