/* ============================================================
 * getObjectPropertyValue
 *
 *    Get property value for an object
 *    It will return the value for a specific property ('name'), or a path to a property ('item.name')
 *    If the Object is an HTMLElement it will try to get the property as a data attribute, a property or the innerText
 *    If the Object is an Array, it will just get the first value
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ============================================================ */

(function ( $, _ ) { /* global _ */
	'use strict';

	// Establish the root object (`window` in the browser)
	var root = this;


	/**
	 *
	 * @param {Object|Array}  item
	 * @param {string|Array=} prop
	 * @param {boolean=}      returnAll - return all values as an Array
	 *
	 * @returns {*}
	 */
	function getObjectPropertyValue ( item, prop, returnAll ) {
		var value;
		
		if ( prop == 'categories' ) {
			for (var category_id in item[prop] ) {
				return category_id;
			}
		}

		if ( _.isElement( item ) ) {

			if ( prop ) {
				value = $( item ).data( prop ) || $( item ).prop( prop );
			} else {
				value = $( item ).text();
			}

		} else if ( _.isObject( item ) ) {

			value = __getObjectPropVal( item, prop, returnAll );

		} else if ( _.isArray( item ) ) {

			if ( prop in item ) {
				value = item[prop];
			} else if ( returnAll ) {
				value = item;
			} else {
				value = item[0];
			}

		} else {

			value = item;

		}

		//if ( value ) {
		//	value = root.zgParseString( value, true );
		//}

		return value;
	}


	/**
	 * Get the values for all object properties
	 *
	 * @param {Object}  obj
	 * @param {Array}   path
	 * @param {boolean} returnAll
	 *
	 * @returns {undefined|Array}
	 *
	 * @private
	 */
	function __getAllObjectPropValues ( obj, path, returnAll ) {
		var prop, temp, values;

		if ( _.isArray( path ) && path.length ) {
			for ( prop in obj ) {
				if ( obj.hasOwnProperty( prop ) ) {
					temp = __getObjectPropValByArray( obj[prop], path, returnAll );

					if ( !_.isUndefined( temp ) && !_.isNull( temp ) ) {
						values = _.union( (values || []), (_.isArray( temp ) ? temp : [temp]) );

						if ( !returnAll ) {
							break;
						}
					}
				}
			}
		} else {
			values = _.values( obj );
		}

		return values;
	}


	/**
	 *
	 * @param {Object}       item
	 * @param {Array|string} prop
	 * @param {boolean=}     returnAll - return all values as an Array
	 *
	 * @returns {*}
	 *
	 * @private
	 */
	function __getObjectPropVal ( item, prop, returnAll ) {
		var value;

		if ( prop in item ) {

			value = item[prop];

		} else if ( _.isArray( prop ) ) {

			value = __getObjectPropValByArray( item, prop, returnAll );

		} else if ( _.isString( prop ) ) {

			value = __getObjectPropValByArray( item, prop.split( '.' ), returnAll );

		}

		return value;
	}


	/**
	 *
	 * @param {Object}   item
	 * @param {Array}    path
	 * @param {boolean=} returnAll - return all values as an Array
	 *
	 * @returns {*}
	 *
	 * @private
	 */
	function __getObjectPropValByArray ( item, path, returnAll ) {
		var valid, i, obj, value;

		obj   = item;
		valid = true;

		for ( i = 0; i < path.length && valid; i++ ) {
			if ( !_.isObject( obj ) ) {

				valid = false; // invalid object

			} else if ( path[i] in obj ) {

				obj = obj[path[i]];

			} else if ( path[i] === '*' ) {

				obj = __getAllObjectPropValues( obj, path.slice( i + 1 ), returnAll );
				break;

			} else {

				valid = false; // the path is not correct

			}
		}

		if ( valid ) {
			value = obj;
		}


		return value;
	}


	// -----------------------------------------------------------------------------------------------------------------


	root.zgGetObjectPropertyValue = getObjectPropertyValue;

}.call( this, jQuery, _ ));
